require("babel-polyfill")
require("typeface-josefin-sans")
require("typeface-fira-sans")
require("typeface-fredoka-one")
require("./src/styles/globals.css")
require("./src/styles/Embla.css")
require("./src/styles/Offcanvas.css")
require("./src/styles/GameMenu.css")
require("./src/styles/Header.css")
require("./src/styles/Layout.css")
require("./src/styles/Quiz.css")
require("./src/styles/Serializers.css")
require("./src/styles/AwsBtn.css")
require("./src/styles/VideoReact.css")
require("./src/styles/pattern.css")
require("./src/styles/algolia.css")
