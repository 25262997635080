exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arbol-de-links-js": () => import("./../../../src/pages/arbol-de-links.js" /* webpackChunkName: "component---src-pages-arbol-de-links-js" */),
  "component---src-pages-buscador-js": () => import("./../../../src/pages/buscador.js" /* webpackChunkName: "component---src-pages-buscador-js" */),
  "component---src-pages-comunidad-js": () => import("./../../../src/pages/comunidad.js" /* webpackChunkName: "component---src-pages-comunidad-js" */),
  "component---src-pages-comunidad-notas-js": () => import("./../../../src/pages/comunidad/notas.js" /* webpackChunkName: "component---src-pages-comunidad-notas-js" */),
  "component---src-pages-comunidad-proyectos-que-nos-potencian-js": () => import("./../../../src/pages/comunidad/proyectos-que-nos-potencian.js" /* webpackChunkName: "component---src-pages-comunidad-proyectos-que-nos-potencian-js" */),
  "component---src-pages-comunidad-recursos-js": () => import("./../../../src/pages/comunidad/recursos.js" /* webpackChunkName: "component---src-pages-comunidad-recursos-js" */),
  "component---src-pages-consulta-virtual-js": () => import("./../../../src/pages/consulta-virtual.js" /* webpackChunkName: "component---src-pages-consulta-virtual-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-propuestas-js": () => import("./../../../src/pages/propuestas.js" /* webpackChunkName: "component---src-pages-propuestas-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-tienda-de-juegos-colecciones-js": () => import("./../../../src/pages/tienda-de-juegos/colecciones.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-colecciones-js" */),
  "component---src-pages-tienda-de-juegos-duracion-js": () => import("./../../../src/pages/tienda-de-juegos/duracion.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-duracion-js" */),
  "component---src-pages-tienda-de-juegos-edades-js": () => import("./../../../src/pages/tienda-de-juegos/edades.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-edades-js" */),
  "component---src-pages-tienda-de-juegos-editoriales-js": () => import("./../../../src/pages/tienda-de-juegos/editoriales.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-editoriales-js" */),
  "component---src-pages-tienda-de-juegos-encontrador-js": () => import("./../../../src/pages/tienda-de-juegos/encontrador.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-encontrador-js" */),
  "component---src-pages-tienda-de-juegos-gift-card-tarjeta-de-regalo-js": () => import("./../../../src/pages/tienda-de-juegos/gift-card-tarjeta-de-regalo.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-gift-card-tarjeta-de-regalo-js" */),
  "component---src-pages-tienda-de-juegos-index-js": () => import("./../../../src/pages/tienda-de-juegos/index.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-index-js" */),
  "component---src-pages-tienda-de-juegos-modalidades-js": () => import("./../../../src/pages/tienda-de-juegos/modalidades.js" /* webpackChunkName: "component---src-pages-tienda-de-juegos-modalidades-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blogSingle.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-collection-single-js": () => import("./../../../src/templates/collectionSingle.js" /* webpackChunkName: "component---src-templates-collection-single-js" */),
  "component---src-templates-game-single-js": () => import("./../../../src/templates/gameSingle.js" /* webpackChunkName: "component---src-templates-game-single-js" */),
  "component---src-templates-publisher-single-js": () => import("./../../../src/templates/publisherSingle.js" /* webpackChunkName: "component---src-templates-publisher-single-js" */),
  "component---src-templates-resourse-single-js": () => import("./../../../src/templates/resourseSingle.js" /* webpackChunkName: "component---src-templates-resourse-single-js" */)
}

